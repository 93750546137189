.adminNav {
  width: 100%;
  font-weight: 300;
  padding: 15px 20px;
  text-decoration: none !important;
  text-align: right;
  cursor: pointer;
}

.adminNav.active {
  color: black !important;
  font-weight: 700;
}

.logout {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.hoverable :hover,
.hoverable:hover td {
  background-color: #fcfcfc;
}

.logout:hover {
  background-color: #dddddd;
}
